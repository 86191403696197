<template>
  <AboutSection :nav-id="sectionId" :bg-class="bgClass">
    <h2 class="patky">
      Dejte nám prosím vědět, jestli přijedete
    </h2>
    <p>
      Abychom byli schopni vše řádně naplánovat - připravit zasedací pořádek, zajistit dostatek jídla, pití a pokoj -
      potřebujeme vědět, kolik vás přijede.
      Pokud již teď víte, že nepřijedete, dejte nám to prosím také vědět.
    </p>
    <p>
      Pro jednoduchost jsme připravili krátký formulář, kde se vás zeptáme na nejnutnější detaily. Odkaz níže Vás
      zavede na novou stránku s formulářem.
    </p>
    <div
        class="story-btn  wow newFadeInUp"
        data-wow-duration="1s"
        data-wow-delay="1000ms"
    >
      <a :href="rsvpFormUrl" target="_blank">Vyplnit formulář</a>
    </div>
  </AboutSection>
</template>
<script>
import AboutSection from "./AboutSection.vue";
import { getId, MENU } from "@/data";

export default {
  name: 'RsvpSection',
  components: {
    AboutSection
  },
  data() {
    return {
      rsvpFormUrl: 'https://forms.gle/gQquiVfHqjF5Km5z5',
      sectionId: getId(MENU.rsvp.to),
      bgClass: MENU.rsvp.bgClass,
    }
  }
}
</script>