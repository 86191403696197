<template>
  <!-- Footer -->
  <footer class="-rn-footer" :id="sectionId">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span>Pro všechny případy</span>
                <h4 class="patky">Kontakt na nás</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Nevěsta</h3>
                <div class="content">
                  <p>
                    <a href="tel:+420 739 032 607">+420 739 032 607</a>
                    <br />
                    <a href="mailto:z.brychtova@seznam.cz">z.brychtova@seznam.cz</a>
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="3" md="3" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Ženich</h3>
                <div class="content">
                  <p>
                    <a href="tel:+420 776 825 413">+420 776 825 413</a>
                    <br />
                    <a href="mailto:jakubkovarik@seznam.cz">jakubkovarik@seznam.cz</a>
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

<!--    <FooterTwo />-->
  </footer>
  <!--// Footer -->
</template>

<script>
// kuba used
import { getId, MENU } from "@/data";

export default {
    name: 'ContactFooter',
    data() {
      return {
        sectionId: getId(MENU.contact.to),
      }
    }

  }
</script>

<style lang="scss" scoped></style>
